import React from "react";
import "../../styles/browsergame/index.css";

class Square extends React.Component {
  render() {
    return <button className='square'>{/* TODO */}</button>;
  }
}

export default Square;
